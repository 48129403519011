import { GridColDef } from "@mui/x-data-grid";
import CurrencyAmount from "components/CurrencyAmount";
import { PublicKeyLink } from "components/PublicKeyLink";
import EditMenuItem from "components/table/EditMenuItem";
import { MoreActionsButton } from "components/table/MoreActionsButton";
import { MembershipType, ofMembership } from "../types/membershipType";
import { Box } from "@mui/material";

export const membershipCols: GridColDef<MembershipType>[] = [
  {
    field: ofMembership("id"),
    headerName: "Id",
  },
  {
    field: ofMembership("image"),
    headerName: "Image",
    renderCell: function render({ row }) {
      return (
        row.image?.url && (
          <Box
            component="img"
            src={row.image?.url}
            sx={(theme) => ({
              maxWidth: theme.spacing(4),
            })}
          />
        )
      );
    },
    minWidth: 120,
    sortable: false,
    flex: 1,
  },
  {
    field: ofMembership("name"),
    headerName: "Name",
    flex: 1,
  },
  {
    field: ofMembership("symbol"),
    headerName: "Symbol",
    flex: 1,
  },
  {
    field: ofMembership("amountOfCents"),
    headerName: "K2T Amount",
    renderCell: function render({ row }) {
      return <CurrencyAmount amountOfCents={row.amountOfCents} />;
    },
    flex: 1,
    minWidth: 100,
  },
  {
    field: ofMembership("mintCount"),
    headerName: "Mint Count",
    type: "number",
    flex: 1,
    minWidth: 100,
  },
  {
    field: ofMembership("membershipKey"),
    headerName: "Mint Key",
    renderCell: function render({ row }) {
      return <PublicKeyLink pubKey={row.membershipKey} type="address" />;
    },
    flex: 1,
    minWidth: 100,
    sortable: false,
  },
  {
    field: "menu",
    headerName: "",
    renderCell: function render({ row }) {
      return (
        <MoreActionsButton>
          <EditMenuItem resource={"memberships"} id={row.id} />
        </MoreActionsButton>
      );
    },
    align: "center",
    maxWidth: 80,
    filterable: false,
    sortable: false,
    disableExport: true,
    disableColumnMenu: true,
  },
];
