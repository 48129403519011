import { Box } from "@mui/material";
import { HttpError } from "@refinedev/core";
import { Create, SaveButton } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import FormTextField from "components/form/FormTextField";
import React from "react";
import { FormProvider } from "react-hook-form";
import { Branch, defaultBranch, ofBranch } from "types/branch";
import FormCheckboxField from "../../components/form/FormCheckboxField";

export default function CreateBranch() {
  const methods = useForm<Branch, HttpError>({
    defaultValues: defaultBranch,
    refineCoreProps: {
      action: "create",
    },
  });

  const {
    handleSubmit,
    refineCore: { onFinish },
  } = methods;

  const handleSave = async (e: React.BaseSyntheticEvent) => {
    await handleSubmit(async (values) => {
      await onFinish({
        ...values,
      });
    })(e);
  };

  return (
    <Create
      footerButtons={() => (
        <SaveButton onClick={(e: React.BaseSyntheticEvent) => handleSave(e)} />
      )}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <FormTextField
            name={ofBranch("name")}
            control={methods.control}
            label={"Name"}
            required
          />
          <FormTextField
            name={ofBranch("abbreviation")}
            control={methods.control}
            label={"Abbreviation"}
            required
            helperText={
              "Identificator of this branch. Cannot be changed later."
            }
          />
          <FormTextField
            name={ofBranch("contact")}
            control={methods.control}
            label={"Contact email"}
            additionalRules={{
              pattern: {
                value: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
                message: "Invalid Email",
              },
            }}
          />
          <FormCheckboxField
            name={ofBranch("useSolana")}
            control={methods.control}
            label={"Store memberships on Solana Blockchain"}
            helperText="Cannot be changed later."
          />
        </Box>
      </FormProvider>
    </Create>
  );
}
