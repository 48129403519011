import { EntityRef } from "utils/rest/entities";
import { Branch } from "./branch";

/** Mirrors key2-server's [com.kurzdigital.key2.key2server.dto.UserDto] */
export interface User extends EntityRef {
  id: number;
  email: string;
  roles: UserRole[];
}

export enum UserRole {
  ADMIN = "ADMIN",
  BRANCH_MANAGER = "BRANCH_MANAGER",
}

export const isBranchManager = (role: string): boolean =>
  role.split("$")[0] === UserRole.BRANCH_MANAGER;

export const getUserRoleLabel = (role: string, branches: Branch[]): string => {
  if (isBranchManager(role)) {
    const branch = branches.find(
      (branch) => branch.abbreviation === role.split("$").pop()
    );
    return `${UserRole.BRANCH_MANAGER} ${branch?.name}`;
  } else return role;
};

export const defaultUser: User = {
  id: 0,
  email: "",
  roles: [],
};
