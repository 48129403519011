import { propertiesOf } from "utils/propertyOf";
import { EntityRef } from "utils/rest/entities";
import { ImageDto } from "./image";

/** Mirrors key2-server's [com.kurzdigital.key2.key2server.dto.BranchDto] */
export interface Branch extends EntityRef {
  id: number;
  name: string;
  abbreviation: string;
  contact: string | null;
  useSolana: boolean;
  showOnEventPage: boolean;
  // coin: TODO,
  style: BranchStyle | null;
}

export interface BranchStyle {
  id: number;
  primaryColor: string;
  secondaryColor: string;
  logoImage: ImageDto | null;
  backgroundImage: ImageDto | null;
}

export const ofBranch = propertiesOf<Branch>();

export const ofBranchStyle = propertiesOf<BranchStyle>();

export const defaultBranchStyle: BranchStyle = {
  id: 0,
  primaryColor: "#333333",
  secondaryColor: "#666666",
  logoImage: null,
  backgroundImage: null,
};

export const defaultBranch: Branch = {
  id: 0,
  name: "",
  abbreviation: "",
  contact: "",
  useSolana: false,
  showOnEventPage: false,
  style: defaultBranchStyle,
};
