import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab, Typography } from "@mui/material";
import { useParsed, useShow } from "@refinedev/core";
import { Show } from "@refinedev/mui";
import TokenImageLink from "components/TokenImageLink";
import { useConfig } from "provider/ConfigProvider";
import { useState } from "react";
import { EventStatistic } from "types/event";
import EventEntries from "./EventEntries";
import EventPayments from "./EventPayments";

export default function EventStatistics() {
  const { id } = useParsed();
  const { queryResult } = useShow<EventStatistic>({
    resource: "events/statistics",
    id,
  });
  const [selectedTab, setSelectedTab] = useState<"entries" | "payments">(
    "entries"
  );
  const { currencyMintAddress } = useConfig();

  const statistics: EventStatistic | undefined = queryResult.data?.data;

  if (!statistics) return <></>;

  const key2TokenTransfer = statistics.transfers.find(
    (transfer) => transfer.mintAddress === currencyMintAddress
  );

  return (
    <Show
      title={
        <Typography variant="h5">
          {statistics.event.title} Statistics
        </Typography>
      }
    >
      <Grid container spacing={10} justifyContent={"center"}>
        <Grid item textAlign="center">
          <Typography variant="h3">{statistics.guestCount}</Typography>
          <Typography variant="subtitle1">Total Guests</Typography>
        </Grid>
        <Grid item>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <Typography variant="h3">
              {key2TokenTransfer ? key2TokenTransfer.amount / 100 : 0}
            </Typography>
            {key2TokenTransfer && (
              <TokenImageLink mintAddress={key2TokenTransfer.mintAddress} />
            )}
          </Box>
          <Typography variant="subtitle1">Key2 Tokens transferred</Typography>
        </Grid>
      </Grid>

      <Box sx={{ width: "100%", typography: "body1" }} marginTop={2}>
        <TabContext value={selectedTab}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList
              onChange={(_, value) => setSelectedTab(value)}
              aria-label=""
            >
              <Tab label={"Entries"} value="entries" />
              <Tab label={"Payments"} value="payments" />
            </TabList>
          </Box>
          <TabPanel value="entries">
            <EventEntries />
          </TabPanel>
          <TabPanel value="payments">
            <EventPayments />
          </TabPanel>
        </TabContext>
      </Box>
    </Show>
  );
}
