import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { CrudFilters } from "@refinedev/core";
import { formatISO } from "date-fns";
import { useState } from "react";
import { MembershipLevel } from "../../types/membershipType";

export default function EventFilter(props: {
  filters: CrudFilters;
  setFilters: ((filters: CrudFilters) => void) &
    ((setter: (prevFilters: CrudFilters) => CrudFilters) => void);
}) {
  const { setFilters } = props;
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const [level, setLevel] = useState("");
  const [search, setSearch] = useState("");

  const resetFilters = () => {
    setFilters([]);
    setFromDate(undefined);
    setToDate(undefined);
    setSearch("");
    setLevel("");
  };

  return (
    <Grid
      container
      display="flex"
      spacing={2}
      alignItems="flex-end"
      justifyContent="flex-start"
      marginBottom={1}
    >
      <Grid item>
        <TextField
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            setFilters((filters) => [
              {
                field: "search",
                value: e.target.value,
                operator: "eq",
              },
              ...filters,
            ]);
          }}
          label="Search"
          variant="standard"
        />
      </Grid>
      <Grid item>
        <DatePicker
          label={"From Date"}
          format="dd/MM/yyyy"
          // @ts-ignore
          value={fromDate || null}
          onChange={(date: Date | null) => {
            if (date) {
              setFromDate(date);
              setFilters((filters) => [
                {
                  field: "fromDate",
                  value: formatISO(date),
                  operator: "eq",
                },
                ...filters,
              ]);
            }
          }}
          slotProps={{
            textField: {
              variant: "standard",
              inputProps: { readOnly: true },
              InputLabelProps: { shrink: true },
            },
          }}
        />
      </Grid>
      <Grid item>
        <DatePicker
          label={"To Date"}
          format="dd/MM/yyyy"
          // @ts-ignore
          value={toDate || null}
          onChange={(date: Date | null) => {
            if (date) {
              setToDate(date);
              setFilters((filters) => [
                {
                  field: "toDate",
                  value: formatISO(date),
                  operator: "eq",
                },
                ...filters,
              ]);
            }
          }}
          slotProps={{
            textField: {
              variant: "standard",
              inputProps: { readOnly: true },
              InputLabelProps: { shrink: true },
            },
          }}
        />
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <InputLabel>Level</InputLabel>
          <Select
            label="Level"
            variant="standard"
            value={level}
            onChange={(e) => {
              setLevel(e.target.value as MembershipLevel);
              setFilters((filters) => [
                {
                  field: "levels",
                  value: e.target.value || undefined,
                  operator: "eq",
                },
                ...filters,
              ]);
            }}
            sx={{ minWidth: 200 }}
          >
            <MenuItem value={""} key={""}>
              {"All"}
            </MenuItem>
            {Object.values(MembershipLevel).map((membership, i) => (
              <MenuItem key={i} value={membership}>
                {membership}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item>
        <Button onClick={resetFilters}>Reset Filter</Button>
      </Grid>
    </Grid>
  );
}
