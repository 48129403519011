import { Box, Typography } from "@mui/material";
import { Create } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import FormTextField from "components/form/FormTextField";
import MembershipToggle from "components/form/MembershipToggle";
import React, { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import {
  CreateRedemptionsRequest,
  defaultRedemptionsRequest,
} from "types/redemption";
import { useMembershipTypes } from "../../utils/hooks/useMembershipTypes";

export default function CreateRedemptions() {
  const membershipTypes = useMembershipTypes();
  const methods = useForm<CreateRedemptionsRequest>({
    defaultValues: defaultRedemptionsRequest,
  });

  const { control, getValues, watch, setValue } = methods;

  const membershipTypeId = watch("membershipTypeId");
  const amountOfCents = membershipTypes?.find(
    (m) => m.id === membershipTypeId
  )?.amountOfCents;

  useEffect(() => {
    const membershipTypeId = getValues("membershipTypeId");
    if (!membershipTypeId && membershipTypes && membershipTypes.length > 0) {
      setValue("membershipTypeId", membershipTypes[0].id);
    }
  }, [membershipTypes, getValues, setValue]);

  return (
    <Create
      saveButtonProps={methods.saveButtonProps}
      title={<Typography variant="h5">Create Redemptions</Typography>}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <MembershipToggle name="membershipTypeId" control={control} />
          <FormTextField
            required
            control={control}
            name="amountOfRedemptions"
            label="Amount of redemptions"
            type="number"
            fullWidth
            additionalRules={{
              min: 0,
            }}
          />
          <FormTextField
            control={control}
            name="amountOfCents"
            label="K2T Amount"
            helperText={
              "The amount of tokens a member will receive when redeeming this membership. Leave blank to use the default amount of this membership." +
              (amountOfCents !== undefined ? ` (${amountOfCents!! / 100})` : "")
            }
            type="number"
            fullWidth
            additionalRules={{
              min: 0,
            }}
            transform={{
              input: (v: any) => {
                if (!v) return v;
                return v * 100;
              },
              output: (v: any) => {
                if (!v) return v;
                return v / 100;
              },
            }}
          />
        </Box>
      </FormProvider>
    </Create>
  );
}
