import { propertiesOf } from "../utils/propertyOf";
import { MembershipType, MembershipLevel } from "./membershipType";
import { Ticket } from "./ticket";
import { Transfer } from "./transaction";

export interface EventDate {
  fromDate: string;
  toDate: string;
  timezone: string;
}

export interface EventLocation {
  streetAddress: string;
  city: string;
  zip: string;
  country: string;
  region: string;
  geoLoc: {
    lat: number | string;
    lng: number | string;
  };
}

export interface Image {
  id: string;
  url: string;
  highlight: boolean;
}

/** Mirrors key-server's EventStatisticsDto */
export interface EventStatistic {
  event: Event;
  guestCount: number;
  transfers: Transfer[];
}

/** Mirrors key2-server's [com.kurzdigital.key2.key2server.dto.app_api.event.EventDto] */
export interface Event {
  id: string;
  branchId: number;
  publicId: string | null;
  slug: string | null;
  code: string | null;
  title: string;
  summary: string | null;
  description: string | null;
  minKey2Level: MembershipLevel | "" | null;
  allowedMembershipTypes: MembershipType[] | null;
  date: EventDate;
  maxGuestCount?: string | number;
  images: Image[] | null;
  tags: string[] | null;
  location: EventLocation | null;
  ageRestriction: AgeRestriction | null;
  draft: boolean;
  private: boolean;
  video: string | null;
  socials: EventSocial | null;
  externalLink: string | null;
  publishAt: string | null;
  k2tPaymentEnabled: boolean | null;
  tickets: Ticket[];
}

export interface EventSocial {
  FACEBOOK?: string;
  INSTAGRAM?: string;
  TIKTOK?: string;
  TWITTER?: string;
}

export const ofEvent = propertiesOf<Event>();

export const defaultEventDate: EventDate = {
  fromDate: "",
  toDate: "",
  timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
};

export const defaultEventLocation: EventLocation = {
  streetAddress: "",
  city: "",
  zip: "",
  country: "",
  region: "",
  geoLoc: {
    lat: "",
    lng: "",
  },
};

export enum AgeRestriction {
  EUROPEAN_ADULT = "18 or older",
  AMERICAN_ADULT = "21 or older",
}

export const defaultEvent: Event = {
  id: "",
  branchId: 0,
  publicId: null,
  slug: "",
  code: null,
  title: "",
  summary: "",
  description: "",
  minKey2Level: "",
  allowedMembershipTypes: [],
  date: defaultEventDate,
  maxGuestCount: "",
  location: defaultEventLocation,
  draft: true,
  private: false,
  k2tPaymentEnabled: false,
  video: "",
  images: [],
  publishAt: "",
  tags: [],
  ageRestriction: AgeRestriction.EUROPEAN_ADULT,
  externalLink: "",
  socials: {
    FACEBOOK: "",
    INSTAGRAM: "",
    TIKTOK: "",
    TWITTER: "",
  },
  tickets: [],
};
