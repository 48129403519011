import { useBranch } from "../../provider/BranchProvider";
import { useList } from "@refinedev/core";
import { MembershipType } from "../../types/membershipType";

export function useMembershipTypes(): MembershipType[] | undefined {
  const branch = useBranch();
  return useList<MembershipType>({
    resource: "membership-types",
    filters: [
      {
        field: "branchId",
        operator: "eq",
        value: branch?.id,
      },
    ],
  })?.data?.data;
}
