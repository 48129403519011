import { Typography, Box } from "@mui/material";
import { RefreshButton } from "@refinedev/mui";
import LoadingScreen from "components/layout/LoadingScreen";
import LogoutButton from "components/layout/LogoutButton";
import React from "react";

export default function NoPermissionsError(props: { user?: string }) {
  const refreshPage = () => window.location.reload();

  return (
    <LoadingScreen loading={false}>
      <Typography variant="h5">This account has no roles</Typography>
      {props.user && (
        <Typography variant="body1" align="center">
          You are signed in as {props.user}.<br />
          You don’t have any permissions in this system.
          <br />
          Please contact the administrator.
        </Typography>
      )}
      <Box>
        <RefreshButton onClick={refreshPage} />
        <LogoutButton />
      </Box>
    </LoadingScreen>
  );
}
