import { useAuth0 } from "@auth0/auth0-react";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigation } from "@refinedev/core";
import { CreateButton, List, useDataGrid } from "@refinedev/mui";
import { useBranches } from "../../provider/BranchProvider";
import { userCols } from "tables/userCols";
import { User } from "types/user";

export default function UserList() {
  const { dataGridProps } = useDataGrid<User>();
  const branches = useBranches();
  const { edit } = useNavigation();
  const { user } = useAuth0();

  return (
    <List headerButtons={<CreateButton>Create User</CreateButton>}>
      <DataGrid
        {...dataGridProps}
        columns={userCols(branches, user)}
        autoHeight
        onRowClick={(params) => {
          edit("users", params.row.id);
        }}
        sx={{
          ...dataGridProps.sx,
          "& .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
      />
    </List>
  );
}
