import { useAuth0 } from "@auth0/auth0-react";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Popover,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
import LogoutButton from "../LogoutButton";
import BranchSelect from "./BranchSelect";
import { HamburgerMenu } from "@refinedev/mui";

/**
 * Customized header component.
 *
 * Based off of [ThemedHeaderV2](https://github.com/refinedev/refine/blob/master/packages/mui/src/components/themedLayoutV2/header/index.tsx).
 * But includes a Branch selector.
 */
export default function CustomHeader() {
  const { user } = useAuth0();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <AppBar color="default" position="sticky" elevation={1}>
      <Toolbar>
        <HamburgerMenu />
        <Stack direction="row" width="100%" alignItems="center" gap={2}>
          <BranchSelect sx={{ maxWidth: "40em" }} />
          <Box
            sx={{
              flexGrow: 1,
            }}
          />
          {user && (
            <>
              <Typography
                alignSelf="center"
                variant="subtitle2"
                sx={{
                  display: { xs: "none", md: "block" },
                }}
              >
                {user.email}
              </Typography>
              <Button onClick={handleClick}>
                <Avatar src={user.picture} alt={user?.name} />
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <LogoutButton />
              </Popover>
            </>
          )}
        </Stack>
      </Toolbar>
    </AppBar>
  );
}
