import { Box, Grid } from "@mui/material";
import { Create } from "@refinedev/mui";
import { useForm } from "@refinedev/react-hook-form";
import FormImageField from "components/form/FormImageField";
import FormTextField from "components/form/FormTextField";
import { FieldValues, FormProvider } from "react-hook-form";
import {
  defaultMembershipType,
  MembershipType,
  ofMembership,
} from "../../types/membershipType";
import { useBranch } from "../../provider/BranchProvider";
import { HttpError } from "@refinedev/core";
import React from "react";

export default function CreateMembershipType() {
  const methods = useForm<MembershipType, HttpError>({
    defaultValues: defaultMembershipType,
  });
  const branch = useBranch();

  const {
    saveButtonProps,
    control,
    refineCore: { onFinish },
    handleSubmit,
  } = methods;

  const customHandleSubmit = (values: FieldValues) => {
    return onFinish({ ...values, branchId: branch?.id });
  };

  return (
    <Create
      saveButtonProps={{
        ...saveButtonProps,
        onClick: handleSubmit(customHandleSubmit),
      }}
    >
      <FormProvider {...methods}>
        <Box
          component="form"
          sx={{ display: "flex", flexDirection: "column" }}
          autoComplete="off"
        >
          <FormTextField
            name={ofMembership("name")}
            control={control}
            label={"Name"}
            required
            additionalRules={{
              maxLength: {
                value: 32,
                message: "Name must be at most 32 characters long",
              },
            }}
          />
          <Grid container spacing={4} marginTop={1} direction={"column"}>
            <Grid item>
              <FormImageField
                control={control}
                name={ofMembership("image")}
                required
                squareImages
              />
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </Create>
  );
}
