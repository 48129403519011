import { CssBaseline, GlobalStyles } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { RefineThemes } from "@refinedev/mui";
import RefineWrapper from "RefineWrapper";
import { AxiosProvider } from "provider/AxiosProvider";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <ThemeProvider theme={RefineThemes.Blue}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        <GlobalStyles styles={{ html: { WebkitFontSmoothing: "auto" } }} />
        <AxiosProvider>
          <BrowserRouter>
            <RefineWrapper />
          </BrowserRouter>
        </AxiosProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
