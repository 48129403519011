import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import FormDateField from "components/form/FormDateField";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import FormCheckboxField from "../../../components/form/FormCheckboxField";
import FormSelectField from "../../../components/form/FormSelectField";
import FormTextField from "../../../components/form/FormTextField";
import { AgeRestriction, ofEvent } from "../../../types/event";
import { MembershipLevel } from "../../../types/membershipType";
import { PublishAtFields } from "./PublishAtFields";
import { MembershipTypeSelect } from "../../../components/form/MembershipTypeSelect";

export function SettingsAndVisibilitySection() {
  const { control, watch, getValues, setValue } = useFormContext();
  const draft = watch("draft");
  const publishDate: Date = new Date(getValues("publishAt"));
  const [editScheduleDate, setEditScheduleDate] = useState(false);
  const [initialScheduleDate, setInitialScheduleDate] = useState(null);
  const publishAt = watch("publishAt");
  const options: Intl.DateTimeFormatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  useEffect(() => {
    if (!editScheduleDate) setInitialScheduleDate(publishAt);
  }, [publishAt, editScheduleDate]);

  return (
    <div>
      <Typography variant="h6">Settings and Visibility</Typography>
      <Box display="flex" flexDirection="row" gap={4}>
        <FormSelectField
          name={ofEvent("minKey2Level")}
          control={control}
          label={"Minimum Key2 level"}
          allowEmpty={true}
          list={Object.values(MembershipLevel)}
        />
        <MembershipTypeSelect />
      </Box>
      <Box display="flex" flexDirection="row" gap={4}>
        <FormSelectField
          name={ofEvent("ageRestriction")}
          control={control}
          allowEmpty={true}
          label={"Age restriction"}
          list={Object.values(AgeRestriction)}
        />
        <FormTextField
          name={ofEvent("maxGuestCount")}
          label="Maximum number of guests"
          control={control}
          type="number"
        />
      </Box>
      <FormCheckboxField
        name={ofEvent("private")}
        control={control}
        label={"Private"}
        helperText="Can only be seen with direct link and is not publicly searchable"
      />

      {!draft && initialScheduleDate && publishDate > new Date() ? (
        <>
          <Typography>
            {"This event is scheduled to be published at: " +
              new Date(initialScheduleDate).toLocaleDateString(
                "en-US",
                options
              ) +
              " " +
              new Date(initialScheduleDate).toLocaleTimeString("en-US")}
          </Typography>
          <Box display="flex" flexDirection="row" gap={4} alignItems={"end"}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={editScheduleDate}
                  onChange={(_, checked) => {
                    setEditScheduleDate(checked);
                    !checked && setValue("publishAt", initialScheduleDate);
                  }}
                />
              }
              label={"Edit Schedule Date"}
            />
            <FormDateField
              name="publishAt"
              control={control}
              disabled={!editScheduleDate}
              label={"Change publish date: "}
              dateProps={{
                disablePast: true,
              }}
              additionalRules={{
                validate: (date, _) =>
                  (date ? new Date(date) >= new Date() : true) ||
                  "Publish date must be in the future",
              }}
            />
          </Box>
        </>
      ) : (
        !draft &&
        initialScheduleDate && (
          <Typography>
            {"This event was published at: " +
              new Date(initialScheduleDate).toLocaleDateString(
                "en-US",
                options
              ) +
              " " +
              new Date(initialScheduleDate).toLocaleTimeString("en-US")}
          </Typography>
        )
      )}
      {draft && <PublishAtFields />}
    </div>
  );
}
