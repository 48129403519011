import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { ofEvent } from "types/event";
import { MembershipType } from "../../types/membershipType";
import { useMembershipTypes } from "../../utils/hooks/useMembershipTypes";

export function MembershipTypeSelect() {
  const { control, watch } = useFormContext();
  const allowedMembershipTypesWatch = watch(ofEvent("allowedMembershipTypes"));
  const membershipTypes = useMembershipTypes();

  if (!membershipTypes) return <></>;
  const noMemberships = membershipTypes.length === 0;

  return (
    <Controller
      control={control}
      name={ofEvent("allowedMembershipTypes")}
      defaultValue={allowedMembershipTypesWatch}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          sx={{ minWidth: 300 }}
          value={field.value || []}
          multiple
          disabled={noMemberships}
          options={membershipTypes}
          getOptionLabel={(m: MembershipType) => m.name}
          onChange={(e, value) => field.onChange(value)}
          isOptionEqualToValue={(option, m) => option.id === m.id}
          renderOption={(props, m, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                checked={selected}
              />
              {m.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              InputLabelProps={{ shrink: true }}
              label={"Allowed Membership Types"}
              error={!!error}
              helperText={
                error?.message ||
                (noMemberships ? "No Membership Types available" : null)
              }
              margin="normal"
              disabled={noMemberships}
            />
          )}
        />
      )}
    />
  );
}
