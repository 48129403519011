import { GridColDef } from "@mui/x-data-grid";
import { MembershipTypeCell } from "../components/table/MembershipTypeCell";
import CouponRuleAirdropDialog from "pages/coupons/rules/CouponRuleAirdropDialog";
import UpdateCouponRuleDistirbutionDialog from "pages/coupons/rules/UpdateCouponRuleDistirbutionDialog";
import { CouponRule } from "types/coupon";

export const couponRuleCols: GridColDef<CouponRule>[] = [
  {
    field: "id",
    headerName: "Id",
  },
  {
    field: "membership",
    headerName: "Membership",
    flex: 1,
    renderCell: function render({ row }) {
      return (
        <MembershipTypeCell
          membershipType={row.membershipType}
          mintAddress={row.membershipType.membershipKey}
        />
      );
    },
  },
  {
    field: "airdropCount",
    headerName: "Airdrop count",
    flex: 1,
    minWidth: 100,
    type: "number",
  },
  {
    field: "createdAt",
    headerName: "Created At",
    renderCell: function render({ row }) {
      return new Date(row.createdAt).toLocaleString();
    },
    flex: 1,
    minWidth: 100,
  },
  {
    field: "active",
    headerName: "Distribution",
    renderCell: function render({ row }) {
      return <UpdateCouponRuleDistirbutionDialog couponRule={row} />;
    },
    align: "center",
  },
  {
    field: "airdrops",
    headerName: "Airdrops",
    renderCell: function render({ row }) {
      return <CouponRuleAirdropDialog couponRule={row} />;
    },
    align: "center",
    filterable: false,
    sortable: false,
    disableExport: true,
    disableColumnMenu: true,
  },
];
