import {
  FormControl,
  FormHelperText,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { Control, Controller } from "react-hook-form";
import { useMembershipTypes } from "../../utils/hooks/useMembershipTypes";

export const StyledToggleButtonGroup = styled(ToggleButtonGroup)(
  ({ theme }) => ({
    "& .MuiToggleButtonGroup-grouped": {
      margin: theme.spacing(1),
      border: 0,
      "&:not(:first-of-type)": {
        border: "1px solid transparent",
        borderRadius: theme.shape.borderRadius,
      },
      "&:first-of-type": {
        border: "1px solid transparent",
        borderRadius: theme.shape.borderRadius,
      },
      "&.Mui-selected": {
        border: "1px solid",
        borderColor: theme.palette.primary.main,
      },
    },
  })
);

export default function MembershipToggle(props: {
  name: string;
  control: Control;
  additionalInfo?: boolean;
}) {
  const { control, name } = props;
  const membershipTypes = useMembershipTypes();

  return (
    <Controller
      control={control}
      name={name}
      rules={{
        required: {
          value: true,
          message: "This is required",
        },
      }}
      render={({ field, fieldState: { error } }) => (
        <FormControl fullWidth margin="normal" sx={{ marginBottom: 4 }}>
          <StyledToggleButtonGroup
            fullWidth
            exclusive
            value={field.value}
            onChange={(_, value) => field.onChange(value)}
          >
            {membershipTypes?.map((membership, i) => (
              <ToggleButton
                value={membership.id}
                key={i}
                sx={{ display: "flex", flexDirection: "column", gap: 1 }}
              >
                <img
                  width="100px"
                  src={membership.image?.url}
                  alt="membership"
                />
                {props.additionalInfo && (
                  <>
                    <Typography variant="h6" fontSize={"16px"}>
                      {membership.name}
                    </Typography>
                    <Typography variant="body2">
                      {membership.mintCount} Owners
                    </Typography>
                  </>
                )}
              </ToggleButton>
            ))}
          </StyledToggleButtonGroup>
          <FormHelperText error>{error?.message}</FormHelperText>
        </FormControl>
      )}
    />
  );
}
