import RuleIcon from "@mui/icons-material/Rule";
import { GridColDef } from "@mui/x-data-grid";
import { PublicKeyLink } from "components/PublicKeyLink";
import TokenImageLink from "components/TokenImageLink";
import { MoreActionsButton } from "components/table/MoreActionsButton";
import ShowMenuItem from "components/table/ShowMenuItem";
import { Coupon, ofCoupon } from "types/coupon";

export const couponCols: GridColDef<Coupon>[] = [
  {
    field: ofCoupon("id"),
    headerName: "Id",
    flex: 1,
  },
  {
    field: ofCoupon("image"),
    headerName: "Image",
    flex: 1,
    renderCell: function render({ row }) {
      return (
        <TokenImageLink
          mintAddress={row.mintAddress}
          fallbackImageUrl={row.image?.url}
        />
      );
    },
    minWidth: 120,
  },
  {
    field: ofCoupon("name"),
    headerName: "Name",
    flex: 1,
  },
  {
    field: ofCoupon("externalUrl"),
    headerName: "External url",
    flex: 1,
  },
  {
    field: ofCoupon("validUntil"),
    headerName: "Valid until",
    renderCell: function render({ row }) {
      return row.validUntil ? new Date(row.validUntil).toLocaleString() : "-";
    },
    flex: 1,
    minWidth: 100,
  },
  {
    field: ofCoupon("mintAddress"),
    headerName: "Token",
    flex: 1,
    renderCell: function render({ row }) {
      return <PublicKeyLink pubKey={row.mintAddress} type="address" />;
    },
  },
  {
    field: "more",
    headerName: "",
    renderCell: function render({ row }) {
      return (
        <MoreActionsButton>
          <ShowMenuItem
            id={row.id}
            resource={"coupons"}
            label="Airdrops"
            startIcon={<RuleIcon />}
          />
        </MoreActionsButton>
      );
    },
    align: "center",
    maxWidth: 80,
    filterable: false,
    sortable: false,
    disableExport: true,
    disableColumnMenu: true,
  },
];
