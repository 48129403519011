import { ThemedSiderV2 } from "@refinedev/mui";
import { useBranch } from "../../provider/BranchProvider";
import CustomTitle from "./CustomTitle";

export const CustomSider = () => {
  const branch = useBranch();
  const isAdminBranch = !branch;
  return (
    <ThemedSiderV2
      Title={CustomTitle}
      render={({ items, logout }) => {
        // Show admin items only if the user is in the admin branch
        // And show nothing else in the admin branch
        const ii = items.filter(
          (i) =>
            !!i.props?.params?.resource?.meta?.adminBranch === isAdminBranch
        );
        return (
          <>
            {ii}
            {logout}
          </>
        );
      }}
    />
  );
};
