import { useLink } from "@refinedev/core";
import { Typography } from "@mui/material";
import React from "react";
import { MecoLogo } from "../svgs";
import MuiLink from "@mui/material/Link";

export default function CustomTitle(props: { collapsed: boolean }) {
  const Link = useLink();
  return (
    <MuiLink
      component={Link}
      to="/"
      underline="none"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
      }}
    >
      <MecoLogo
        sx={{
          height: 32,
          width: 32,
        }}
      />
      {!props.collapsed && (
        <Typography
          variant="h6"
          color="text.primary"
          sx={{
            fontFamily: "Montserrat",
          }}
        >
          meco
        </Typography>
      )}
    </MuiLink>
  );
}
