import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { useCan, useGo } from "@refinedev/core";
import { useBranchContext } from "../../../provider/BranchProvider";
import { useEffect } from "react";

export default function BranchSelect(props: { sx?: SxProps<Theme> }) {
  const { branches, branch } = useBranchContext();
  const go = useGo();

  const canAdmin = useCan({ resource: "admin", action: "list" }).data?.can;

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value.toString();
    if (value === "admin") {
      go({ to: "/admin/branches" });
      return;
    }
    const newBranch = branches.find((b) => b.id.toString() === value);
    go({
      to: `/branches/${newBranch?.id}`,
    });
  };

  useEffect(() => {
    if (canAdmin === undefined) return;
    if (!branch && !canAdmin && branches.length >= 1) {
      go({ to: `/branches/${branches[0].id}` });
    }
  }, [branch, branches, canAdmin, go]);

  if (canAdmin === undefined) {
    // Wait for `canAdmin` to be loaded
    return null;
  }

  if (!canAdmin) {
    if (!branch) {
      return <p>No permission for any branches</p>;
    } else {
      return (
        <Typography alignSelf="center" variant="h6">
          {branch.name}
        </Typography>
      );
    }
  }

  return (
    <FormControl fullWidth sx={props.sx}>
      <InputLabel variant="standard">Branch</InputLabel>
      <Select
        size="small"
        label="Branch"
        variant="standard"
        value={branch?.id.toString() || (canAdmin ? "admin" : "")}
        onChange={handleChange}
        sx={{ maxWidth: "550px" }}
      >
        {canAdmin && (
          <MenuItem
            value={"admin"}
            sx={{ borderBottom: "1px solid lightgrey" }}
          >
            Admin Branch
          </MenuItem>
        )}
        {branches.map((branch) => (
          <MenuItem key={branch.id} value={branch.id}>
            {branch.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
